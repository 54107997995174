.homepage {
  /* touch-action: none; */
  height: 100vh;
  max-height: -webkit-fill-available;
  width: 100vw;
  font-family: arial;
  font-size: 13px;
}



.carousel {
  width: 100vw;
}

.carousel-img, .carousel-img-mob {
  padding-top: 100px;
  display: block;
  margin: auto;
  max-height: 79vh;
  max-width: 91vw;
}

.carousel-img-mob {
  padding-top: 8vh;
}



.project-h::-webkit-scrollbar, .images-h::-webkit-scrollbar {
  display: none;
}


.project-inner, .project-inner-mob {
  /* width: 70vw; */
  max-width: 1500px;
  height: 100%;
  margin: 0 auto;
}

/* .project-inner-mob { */
/*   width: 88vw !important; */
/* } */

.project-h {
  margin-top: 10vh;
  padding-left: 0;
  width: 100%;
  height: 80vh;
  overflow-y: hidden; /* Hide vertical scrollbar */
  white-space: nowrap;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@media (pointer:none), (pointer:coarse) {
  .project-h {
    height: 69vh;
  }
}

.p-wrap-h {
  height: 100%;
}

.project-image-row-h {
  height: 90%;
  margin-bottom: 0.8vh;
}

.images-h {
  touch-action: auto;
  width: max-content;
  height: 100%;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.images-row-h {
  touch-action: pan-x;
  height: 100%;
  /* padding-right: 400px; */
}

.project-title-wrap-h, .project-info-wrap-h{
  position: relative;
  width: 50vh;
  height: 100%;
  /* background-color: #f6f6f6; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-infos-h{
  min-width: 30vh;
}

.designteam-h{
  text-align: justify;
  align-items: center;
}

.project-title-h, .project-infos-h{
  text-align: center;
  justify-content: center;
  align-items: justify;
  white-space: initial;
  font-size: 1.6vh;
}

.project-info-h {
  display: block;
  font-size: 2.4vh;
}

.info-row-h {
  width: 1600px;
  height: 100px;
  margin-top: 10px;
  margin-left: 10px;
}

.info-col-h {
  margin-right: 60px;
}

.info-headers-h {
  margin-top: 6px;
  font-size: 0.9vh;
  align-items: justify;
}

.info-value-h {
  align-items: justify;
}

.image-col-h {
  margin-right: 10px;
  height: 100%;
  /* width: 100vw; */
}

.project-image-wrap-h {
  position: relative;
  /* width: 900px; */
  display: inline-block;
  height: 100%;
}


.project-image-h {
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: relative;
  /* z-index: 10; */
  animation: createBox 1s;
}

/* .project-image:hover { */
/*   animation: createBoxIn 1s; */
/*   animation-fill-mode: forwards; */
/* } */


.new-spin {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-image-img{
  width: auto !important;
}

.project-desc-h-row {
  position: relative;
}

.project-desc-h{
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 1.5vh;
  white-space: normal;
}

.project-desc-h-mob{
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 1.9vh;
  white-space: normal;
}
