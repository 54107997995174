.projectpage {
  /* touch-action: none; */
  height: 100vh;
  max-height: -webkit-fill-available;
  width: 100vw;
}


.project::-webkit-scrollbar, .images::-webkit-scrollbar {
  display: none;
}


.project {
  display:inline-block;
  padding-top: 114px;
  padding-left: 0;
  width: 100%;
  height: 95vh;
  white-space: nowrap;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.project-image-row {
  width: 100%;
}
.images {
  margin-left: auto;
  margin-right: auto;
  width: 91vw;
  max-width: 1600px;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.images-row {
  /* padding-right: 400px; */
}

.project-title-wrap, .project-info-wrap{
  position: fixed;
  top: 50px;
  width: 100%;
  /* background-color: #f6f6f6; */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  pointer-events: none;
}

.project-infos{
  min-width: 30vh;
}

.designteam{
  text-align: justify;
  align-items: center;
}

.project-title, .project-infos{
  font-size: 20px;
  text-align: center;
  justify-content: center;
  align-items: justify;
  white-space: initial;
  margin-bottom: 20px;
}

.project-info {
  display: block;
}

.info-row {
  width: 1600px;
  margin-top: 10px;
  margin-left: 10px;
}

.info-col {
  margin-right: 60px;
}

.info-headers {
  margin-top: 6px;
  align-items: justify;
}

.info-value {
  align-items: justify;
}

.image-col {
}

.project-image-wrap {
  width: 100%;
  position: relative;
  /* width: 900px; */
  display: inline-block;
  animation: createBox 1s;
  margin-bottom: 20px;
}

.project-analysis {
  white-space: pre-line !important;
  text-align: justify;
  justify-content: left;
  align-items: left;
  width: 100%;
  white-space: initial;
  font-size: 20px;
  overflow-y: hidden;
  margin-bottom: 20px;
}

.project-analysis-wrap {
  position: relative;
  width: 100%;
  /* background-color: #f6f6f6; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-image {
  display: block;
  width: 100%;
  position: relative;
  /* z-index: 10; */
  animation: createBox 1s;
  margin-bottom: 20px;
}

/* .project-image:hover { */
/*   animation: createBoxIn 1s; */
/*   animation-fill-mode: forwards; */
/* } */


.new-spin {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-image-img{
  width: auto !important;
}

.mob-info{
  margin-top:10px;
}
