.contactpage {
  height: 100vh;
  max-height: -webkit-fill-available;
  width: 100vw;
  font-family: arial;
}

.contact {
  margin: auto;
  padding-top: 18vh;
  height: 625px;
  width: 80vw;
  max-width: 1400px;
}

.contact-row {
  height: 100%;
}

.contact-wrap {
  position: relative;
  width: 40vw;
  height: 625px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-details {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  height: 500px;
  width: 350px;
  white-space: initial;
}

.mob-contact-wrap {
  margin: auto;
  position: relative;
  width: 40vw;
  height: 255px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.map-wrap {
  /* margin: auto; */
  position: relative;
  width: 80vw;
  margin-bottom: 60px;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
}

.mob-contact-details {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  width: 70%;
  min-width: 324px;
  max-width: 400px;
  white-space: initial;
}

.map-img {
  max-width: 100%;
}
.mob-map-img {
  width: 80vw;
}

.contact-facebook, .contact-instagram {
  color: #989898;
  text-decoration: none;
}
.contact-facebook:hover, .contact-instagram:hover {
  color: #dc8960;
}
