@keyframes fade {
  0% {
    transform: scaleY(1);
    opacity: 1;
  }
  80% {
    transform: scaleY(0.1);
  }
  100% {
    transform: scaleY(0.1);
    opacity: 0.4;
  }
}

@keyframes rotate-keyframes {
  from {
  transform: rotate(0deg);
 }

 to {
  transform: rotate(360deg);
 }
}


.header-wrap {
  background-color: white;
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
}

.header-mob {
  margin-left: auto;
  margin-right: auto;
  width: 91vw !important;
  max-width: 1600px;
  margin-bottom: 6px;
  height: 60px;
}

.header {
  margin-left: auto;
  margin-right: auto;
  width: 91vw !important;
  max-width: 1600px;
  margin-bottom: 11px;
  height: 102px;
}

.logo-txt {
  font-size: 70px;
}

.menu-img {
  margin-top:52px;
  cursor: pointer;
  height: 20px;
}

.menu-img-mob {
  margin-top:32px;
  cursor: pointer;
  height: 15px;
}

.homepage-img {
  margin-top:40px;
  height: 35px;
}

.homepage-img-mob {
  margin-top:30px;
  height: 25px;
}

.grid-img {
  margin-right:20px;
  height: 2.3vh;
  max-height: 25px;
}

.closing {
  -webkit-animation: fade 0.3s ease-in both normal;
  -moz-animation:    fade 0.3s ease-in both normal;
  -o-animation:      fade 0.3s ease-in both normal;
  -ms-transition:    fade 0.3s ease-in both normal;
  animation:         fade 0.3s ease-in both normal;
}

.opening {
  -webkit-animation: fade 0.6s ease-out both reverse;
  -moz-animation:    fade 0.6s ease-out both reverse;
  -o-animation:      fade 0.6s ease-out both reverse;
  -ms-transition:    fade 0.6s ease-out both reverse;
  animation:         fade 0.6s ease-out both reverse;
}

.rotate {
  -webkit-animation: rotate-keyframes 1.2s;
  -moz-animation:    rotate-keyframes 1.2s;
  -o-animation:      rotate-keyframes 1.2s;
  -ms-transition:    rotate-keyframes 1.2s;
  animation:         rotate-keyframes 1.2s;
}


