.footer {
  position: fixed;
  bottom: 2vh;
  z-index: 999;
  font-family: arial;
  font-size: 19px;
}

.email {
  margin-left: 20px;
}

.language {
  margin-right: 20px;
  color: #d3d3d3;
}

.lang {
   cursor:pointer;
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}
