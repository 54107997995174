.menu {
  height: 100vh;
  max-height: -webkit-fill-available;
  width: 100vw;
  background-color: white;
  z-index: 11;
  overflow-y: scroll;
}

.menu-container {
  margin-left: auto;
  margin-right: auto;
  padding-top: 200px;
  max-width: 1600px;
  width: 91vw;
  height:100vh;
}
.akra-desc {
  line-height: 58px;
  max-width: 1300px;
  text-align: left;
  font-size: 46px;
}

.menu-language {
  color: #d3d3d3;
}

.menu-items, a {
  margin-bottom: 0.9vh;
}
.menu-items:hover, .menu-items.active, .facebook:hover, .instagram:hover {
  color: #dc8960;
}

.menu-social {
  float: bottom;
  padding-top: 200px;
  width: 91vw;
  height: 310px;
  max-width: 1600px;
  font-size: 45px;
}

.hello, .instagram {
  text-decoration: none;
  float: left;
  margin-bottom: 30px;
}
.hello:hover, .instagram:hover {
  padding-top: 1px;
  text-decoration: line-through;
}

.instagram {
  float: right;
}

.facebook {
  margin-right: 20px;
}

.copyright {
  font-size: 11px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: white;
  text-align: center;
}
