.edit-projects {
  padding-top: 7vh;
  margin: auto;
  width: 76%;
  font-size: 22px;
}

.edit-filters-wrap {
  padding-top: 1vh;
  margin: auto;
  width: 76%;
}
