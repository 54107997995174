.studiopage {
  height: 100vh;
  max-height: -webkit-fill-available;
  width: 100vw;
  font-family: arial;
}

.studio {
  margin: auto;
  padding-top: 15vh;
  height: 625px;
  width: 80vw;
  max-width: 1000px;
}

.studio-mob {
  margin: auto;
  padding-top: 12vh;
  width: 88vw;
}

.studio-row-mob {
  width: 200vw;
}

.studio-row {
  height: 100%;
}

.studio-desc-title, .studio-team-title {
  font-size: 1.4vh;
  color: #b76237;
  margin-bottom: 2.5vh;
}
.studio-team-title {
  margin-bottom: 3.6vh;
}

.studio-desc-title-mob, .studio-team-title-mob {
  font-size: 2.2vh;
  color: #b76237;
  margin-bottom: 2.5vh;
}

.studio-team-title-mob {
  margin-bottom: 3.6vh;
}
.studio-desc {
  white-space: pre-line;
  text-align: justify;
  font-size: 1.7vh;
}

@media screen and (min-height: 1000px) {
  .studio-desc {
     font-size: 17.8px;
  }
}

.studio-desc-mob {
  white-space: pre-line;
  text-align: left;
  font-size: 3.5vh;
  padding: 4vh;
  overflow: auto;
  height: 66vh;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.studio-desc-mob::-webkit-scrollbar {
  display: none;
}

.studio-team {
  white-space: pre-line;
  text-align: justify;
  font-size: 2.0vh;
  padding: 2vh;
  margin-left: 120px;
}

.studio-team-mob {
  white-space: pre-line;
  text-align: justify;
  font-size: 6.0vh;
  padding: 2vh;
  margin-left: 16vw;
}

.studio-emp{
  font-size: 1.6vh;
  margin-bottom: 2.4vh;
}
.studio-emp-desc{
  font-size: 1.0vh;
}

.studio-emp-mob{
  font-size: 2.4vh;
  margin-bottom: 2.4vh;
}
.studio-emp-desc-mob{
  font-size: 1.7vh;
}

.studio-wrap {
  position: relative;
  width: 40vw;
  height: 625px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.studio-team {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  height: 500px;
  width: 350px;
  white-space: initial;
}

.mob-studio-wrap {
  margin: auto;
  position: relative;
  width: 40vw;
  height: 255px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.studio-arrow-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.studio-arrow-col {
  color: #b76237;
  font-size: 30px;
}

