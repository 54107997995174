.gridpage{
  /* touch-action: none; */
  height: 100vh;
  max-height: -webkit-fill-available;
  width: 100vw;
}

.grid-projects::-webkit-scrollbar, .grid-projects-mob::-webkit-scrollbar, .projects::-webkit-scrollbar {
  display: none;
}

.grid-projects {
  width: 91vw;
  height: 95vh;
  padding-top: 114px;
  /* overflow-x: auto; */
  /* overflow-x: hidden; */
  white-space: nowrap;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
}

.grid-projects-mob {
  width: 91vw;
  height: 95vh;
  padding-top: 70px;
  /* overflow-x: auto; */
  /* overflow-x: hidden; */
  white-space: nowrap;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
}

.projects {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  width:100%;
  /* padding-top: 114px; */
}
.projects-col, .image-link {
  width: 100%;
}
.projects-col {
}

.projects-row {
  height: 100%;
  padding-right: 12vw;
}

.image-row {
  padding-bottom: 3px;
  height: 100%;
}
.image-wrap {
  position: relative;
  /* width: 600px; */
  display: inline-block;
  width: 100%;
  /* cursor: pointer; */
  /* background-color: #e0e0e0; */
}

.image {
  display: block;
  width: 100%;
  /* position: absolute; */
  left: 0;
  top: 0;
  z-index: 10;
  animation: createBox 1s;
  border: 4px solid transparent;
}

.image:hover {
  border: 4px solid #262626;
  animation-name: createBox, createBorder;
  animation-duration: 1s, 0.6s;
}
.cols {
  margin-right: 20px;
}

.grid-row {
  float: right;
}

.filter {
  color: #d3d3d3;
  font-family: arial;
  font-size: 14px;
   cursor:pointer;
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}
@keyframes createBox {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes createBorder {
  0% {
  border: 4px solid transparent;
  }
  100% {
  border: 4px solid #262626;
  }
}

